import React from "react"

import SEO from "../components/seo"
import SimpleLayout from "../components/Layout/SimpleLayout"

import styles from "../styles/404.module.css"
import Pontuation from "../components/Pontuation/Pontuation"

const NotFoundPage = (props) => (
  <SimpleLayout path={props.path}>
    <SEO title="404: Not found" />
    <header className={styles.header}>
      <div className={styles.container}>
        <h1 className={styles.sectionTitle}>
          Page not found<Pontuation>...</Pontuation>
        </h1>
        <p className={styles.sectionDescription}>
          You just hit a route that doesn&#39;t exist... the sadness.
        </p>
      </div>
    </header>
  </SimpleLayout>
)

export default NotFoundPage
